import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { useStyles } from "./useStyles";
import { RadioInputProps } from "../formInputProps";
import { MuiTheme } from "../../../../helpers";
import { Chip } from "../../../buttons";
import { IRadioOptions } from "../../../../models/rfq/rfq-types";

export const FormInputRadio: React.FC<RadioInputProps> = ({ name, control, label, options, isChip }) => {
    const classes = useStyles();

    const generateRadioOptions = (value: IRadioOptions, onChange: (...event: any[]) => void) => {
        return options.map((singleOption) =>
            isChip ? (
                <Chip
                    key={singleOption.value}
                    label={singleOption.label}
                    onSelect={() => onChange(singleOption.value)}
                    selected={singleOption.value === value}
                    isRfqChip
                />
            ) : (
                <FormControlLabel
                    key={singleOption.value}
                    value={singleOption.value}
                    label={singleOption.label}
                    className={classes.smallRadioButton}
                    control={<Radio />}
                />
            ),
        );
    };

    return (
        <MuiTheme>
            <FormControl component="fieldset">
                <FormLabel component="legend">{label}</FormLabel>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup
                            sx={{ flexDirection: "row" }}
                            className={classes.radioButton}
                            value={value}
                            onChange={onChange}
                        >
                            {generateRadioOptions(value, onChange)}
                        </RadioGroup>
                    )}
                />
            </FormControl>
        </MuiTheme>
    );
};
