import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../assets/data/ui";
import { theme } from "../../../assets/theme/theme";

const useStyles = makeStyles({
    formWrapper: {
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        padding: "32px",
        margin: "0px auto",
        maxWidth: "720px",
        width: "100%",
    },
    button: {
        minWidth: "80px",
        borderRadius: "32px",
        alignSelf: "center",
        backgroundColor: COLORS.success,
        color: COLORS.primaryDark,
        marginTop: "28px !important",
        "&:hover": {
            backgroundColor: COLORS.success,
        },
    },
    noBoxShadow: {
        boxShadow: "none !important"
    },
    headerWrapper: {
        margin: "0px auto 18px",
        textAlign: "center",
        marginTop: "24px",
    },
    header: {
        padding: "10px",
        fontWeight: "700",
        fontSize: "24px",
        color: `${COLORS.main}`,
    },
    inputsRadioWrapper: {
        margin: "0px auto",
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        width: "40%",
        minWidth: "400px",
        marginBottom: "20px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            "& fieldset > [class*='radioButton']": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "18px",
            }
        },
    },
    inputsWrapper: {
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "40%",
        minWidth: "400px",
    },
    additionalTextareaWrapper: {
        margin: "0px auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "40%",
            minWidth: "480px",
        }
    },
});

export default useStyles;
