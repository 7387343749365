import mixpanel from "mixpanel-browser";

const canTrack = true; //TODO: this is mock var - need to present a popup, save response in localStorage and read value from local storage.
let isMixpanelInitialized = false;

export const initMixpanel = () => {
    if (canTrack && !isMixpanelInitialized) {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_KEY!, {
            debug: true,
        });
        mixpanel.set_config({ persistence: "localStorage" });
        isMixpanelInitialized = true;
    }
};

export const setUserId = (userId?: string) => {
    const identifier = userId ? userId : "Anonymous";
    mixpanel.identify(identifier);
};

export const registerSuperPropertiesForMixpanel = (superProperties: { [key: string]: any }) => {
    mixpanel.register(superProperties);
};

export const optOutUser = () => {
    mixpanel.opt_out_tracking();
};

export const tryTrackEvent = (eventMsg: string, dataToSave?: Record<string, string>) => {
    if (!canTrack) {
        console.warn("Can't track event because user opted out of tracking");
        return;
    }
    if (!isMixpanelInitialized) {
        console.error("Mixpanel is not initialized");
        return;
    }
    const identity = mixpanel.get_distinct_id();
    if (!identity) {
        console.error("Mixpanel identity is not set");
        return;
    }
    mixpanel.track(eventMsg, dataToSave);
};
