import { Popper } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ScreenWidthType } from "../../assets/data/ui";
import { BoatIconV2, InvoiceIcon } from "../../assets/icons";
import { SearchBar } from "../../components";
import PillContainer from "../../components/PillContainer/PillContainer";
import { getHighlightedText } from "../../components/cards/shipment-cards/shared/services/shipment-cards.service";
import { useScreenWidthType, useSearchDebounce } from "../../helpers/custom-hooks";
import { tryTrackEvent } from "../../helpers/services/MixPanelService";
import { IShipmentQueryParams } from "../../helpers/services/ShipmentService";
import { IShipment } from "../../models";
import { FilterTypes, InvoicesFilterKeyNames, InvoicesFilterLabels } from "../../models/filters/filters.interfaces";
import { IInvoice } from "../../models/invoices/invoices.interfaces";
import { useAppDispatch, useAppSelector } from "../../store";
import {
    useGetInvoicesByCustomerCodeQuery,
    useLazyGetInvoicesByCustomerCodeQuery,
} from "../../store/api-slices/invoicesSlice.api";
import { useLazyGetShipmentsByCustomerCodeQuery } from "../../store/api-slices/shipmentsSlice.api";
import { initialState } from "../../store/reducers/invoicesSlice.store";
import { setGlobalShipment } from "../../store/reducers/shipmentsSlice.store";
import SkeletonLoaderItem from "../ui-decorations/SkeletonLoader/SkeletonLoaderItem";
import SpinnerLoader from "../ui-decorations/SpinnerLoader/SpinnerLoader";

import useStyles from "./useStyles";

const renderLoader = (
    <div className="LoaderContainer" style={{ width: "100%", display: "flex", alignItems: "center", gap: "1rem" }}>
        <SkeletonLoaderItem predefinedStyle="Circle" />
        <SkeletonLoaderItem width="10rem" />
        <SkeletonLoaderItem width="8rem" />
        <SkeletonLoaderItem width="6rem" />
    </div>
);

interface HeaderProps {
    shouldShowLoader: boolean;
}

const Header = ({ shouldShowLoader }: HeaderProps) => {
    const initialPageInSearchBar = 1;
    const limitOfItemsInSearchBar = 10;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;

    const userState = useAppSelector((state) => state.rootReducer.users);

    const [pageInSearchBar, setPageInSearchBar] = useState(initialPageInSearchBar);
    const [didFinishedPossibleSearches, setDidFinishedPossibleSearches] = useState(false);
    const [popupItems, setPopupItems] = useState<(IShipment | IInvoice)[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const searchDebouncedValue = useSearchDebounce(searchValue);

    const searchContainerEl = useRef<HTMLDivElement>(null);
    const searchBarPopupRef = useRef<HTMLDivElement>(null);
    const [searchAnchorEl, setSearchAnchorEl] = useState<HTMLElement | null>(null);

    const [
        // Get shipment from shipment number. This is used when the user clicks on an invoice item in the search bar and in mobile view.
        triggerGetShipmentFromInvoiceItemClick,
        {
            data: shipmentFromInvoiceData,
            isFetching: isFetchingShipmentFromInvoice,
            isSuccess: isSuccessFetchingShipmentFromInvoice,
            isError: isErrorFetchingShipmentFromInvoice,
        },
    ] = useLazyGetShipmentsByCustomerCodeQuery();

    const [
        triggerShipmentsSearchReq,
        {
            data: searchedShipmentsData,
            isFetching: isFetchingShipments,
            isSuccess: isSuccessFetchingShipments,
            isError: isErrorFetchingShipments,
        },
    ] = useLazyGetShipmentsByCustomerCodeQuery();
    const [
        triggerInvoicesSearchReq,
        {
            data: searchedInvoicesData,
            isFetching: isFetchingSearchedInvoices,
            isSuccess: isSuccessFetchingSearchedInvoices,
            isError: isErrorFetchingSearchedInvoices,
        },
    ] = useLazyGetInvoicesByCustomerCodeQuery();

    const triggerGetNewSearchData = (queryParams: IShipmentQueryParams) => {
        // If the query params changed, get the relevant shipments and invoices
        if (!queryParams.search) return;
        triggerShipmentsSearchReq({ customerCode: userState.code, params: queryParams });
        triggerInvoicesSearchReq({
            customerCode: userState.code,
            params: {
                filters: [
                    {
                        filterType: FilterTypes.MULTI_CHOICE_SELECT,
                        keyName: InvoicesFilterKeyNames.STATUS,
                        includeAllOption: true,
                        label: InvoicesFilterLabels.STATUS,
                        options: [],
                    },
                ],
                page: queryParams.page,
                limit: queryParams.limit,
                sortOrder: initialState.sort.sortOrder,
                sortType: initialState.sort.sortType,
                search: queryParams.search,
            },
        });
    };

    const searchLoader = (
        <div style={{ display: "flex", alignItems: "center", gap: "0.75rem" }}>
            <SpinnerLoader size="2rem" />
            <span>Loading...</span>
        </div>
    );

    const { data: invoicesData } = useGetInvoicesByCustomerCodeQuery({
        customerCode: userState.code,
        params: {
            page: initialState.page,
            limit: limitOfItemsInSearchBar,
            filters: initialState.filters,
            sortOrder: initialState.sort.sortOrder,
            sortType: initialState.sort.sortType,
            search: "",
        },
    });

    useEffect(() => {
        if (isFetchingShipmentFromInvoice) return;
        if (isErrorFetchingShipmentFromInvoice) {
            console.log("Error fetching shipment from invoice");
        }
        if (
            isSuccessFetchingShipmentFromInvoice &&
            shipmentFromInvoiceData &&
            shipmentFromInvoiceData.shipments?.length > 0 &&
            shipmentFromInvoiceData.shipments[0]
        ) {
            handleSelectShipmentItemClicked(shipmentFromInvoiceData.shipments[0]);
            return;
        }
    }, [
        isSuccessFetchingShipmentFromInvoice,
        shipmentFromInvoiceData,
        isFetchingShipmentFromInvoice,
        isErrorFetchingShipmentFromInvoice,
    ]);

    useEffect(() => {
        setPopupItems([]);
        setPageInSearchBar(initialPageInSearchBar);
        setDidFinishedPossibleSearches(false);
        triggerGetNewSearchData({
            search: searchDebouncedValue,
            page: initialPageInSearchBar,
            limit: limitOfItemsInSearchBar,
        });
    }, [searchDebouncedValue]);

    useEffect(
        // If a new search page is requested, get new data
        () => {
            if (!searchDebouncedValue || didFinishedPossibleSearches) return;
            triggerGetNewSearchData({
                search: searchDebouncedValue,
                page: pageInSearchBar,
                limit: limitOfItemsInSearchBar,
            });
        },
        [pageInSearchBar],
    );

    useEffect(() => {
        if (!searchBarPopupRef.current) return;
        const searchBarPopup = searchBarPopupRef.current;
        const handleReachedBottomOfPopup = () => {
            const didReachBottom =
                searchBarPopup.scrollTop + searchBarPopup.clientHeight === searchBarPopup.scrollHeight;
            if (didReachBottom && !didFinishedPossibleSearches && !isFetchingShipments && !isFetchingSearchedInvoices) {
                setPageInSearchBar((prev) => prev + 1);
            }
        };
        searchBarPopup.addEventListener("scroll", handleReachedBottomOfPopup);
        return () => searchBarPopup.removeEventListener("scroll", handleReachedBottomOfPopup);
    }, [searchBarPopupRef.current, didFinishedPossibleSearches, isFetchingShipments, isFetchingSearchedInvoices]);

    useEffect(() => {
        if (isFetchingShipments || isFetchingSearchedInvoices) return;

        if (isSuccessFetchingShipments && isSuccessFetchingSearchedInvoices && !didFinishedPossibleSearches) {
            const newShipments = searchedShipmentsData?.shipments || [];
            const newInvoices = searchedInvoicesData?.invoices || [];

            setPopupItems((prevItems) => {
                // Concatenate new data with the previous items
                const updatedItems = [...prevItems, ...newShipments, ...newInvoices];
                // Set didFinishedPossibleSearches if new data is less than limit
                if (newShipments.length < limitOfItemsInSearchBar && newInvoices.length < limitOfItemsInSearchBar) {
                    setDidFinishedPossibleSearches(true);
                }
                return updatedItems;
            });
        }
    }, [
        searchedShipmentsData,
        searchedInvoicesData,
        isFetchingShipments,
        isFetchingSearchedInvoices,
        didFinishedPossibleSearches,
        isSuccessFetchingShipments,
        isSuccessFetchingSearchedInvoices,
    ]);

    const handleSearchChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = event.target;
        setSearchValue(value);
        setSearchAnchorEl(value !== "" ? event.currentTarget : null);
    };

    const handleGetReviewAndPay = () => {
        tryTrackEvent("[Overview Page]: 'Review & Pay' button clicked");
        navigate("/payments");
    };

    const handleSelectShipmentItemClicked = (shipment: IShipment) => {
        tryTrackEvent("[Search bar]: Selected item from search clicked");
        dispatch(
            setGlobalShipment({
                shipment: shipment,
                params: { search: searchDebouncedValue, page: pageInSearchBar, limit: limitOfItemsInSearchBar },
            }),
        );
        navigate("/shipments");
    };

    const handleSelectInvoiceItemClicked = (invoice: IInvoice) => {
        // TODO: Implement this further by adding deep linking to the invoice in the payments page
        tryTrackEvent("[Search bar]: Selected item from search clicked");
        navigate(`/payments?invoiceNumber=${invoice.invoiceNumber}`);
    };

    const renderShipmentItem = (shipment: IShipment) => {
        const { shipmentName, shipmentNumber } = shipment.shipmentDetails;
        const { purchaseOrderNumber } = shipment.preDelivery;
        return (
            <div
                onClick={() => handleSelectShipmentItemClicked(shipment)}
                className={classes.searchBarItemContainer}
                key={shipment.shipmentDetails.shipmentNumber}
            >
                <BoatIconV2 width={26} className={classes.searchItemIcon} />
                <div className={classes.searchBarItemDetails}>
                    {shipmentNumber && getHighlightedText(shipmentNumber, searchDebouncedValue, false)}
                    {purchaseOrderNumber ? (
                        <>
                            {shipmentName || shipmentNumber ? <div className={classes.divider} /> : null}
                            {getHighlightedText(purchaseOrderNumber, searchDebouncedValue, false)}
                        </>
                    ) : null}
                </div>
            </div>
        );
    };

    const renderInvoiceItem = (invoice: IInvoice) => (
        <div
            onClick={() => {
                if (isMobile) {
                    // Get shipment via invoice.shipment.shipmentNumber
                    triggerGetShipmentFromInvoiceItemClick({
                        customerCode: userState.code,
                        params: {
                            search: invoice.shipment.shipmentNumber,
                            page: initialPageInSearchBar,
                            limit: 1,
                        },
                    });
                    return;
                }
                handleSelectInvoiceItemClicked(invoice);
            }}
            className={classes.searchBarItemContainer}
            key={invoice.invoiceNumber}
        >
            <InvoiceIcon width={26} className={classes.searchItemIcon} />
            <div className={classes.searchBarItemDetails}>
                {getHighlightedText(invoice.invoiceNumber, searchDebouncedValue, false)}
            </div>
        </div>
    );

    const renderPopupItems = () => {
        return popupItems.length === 0 && (isFetchingShipments || isFetchingSearchedInvoices) ? (
            searchLoader
        ) : !popupItems || isErrorFetchingShipments || isErrorFetchingSearchedInvoices ? (
            <div className={classes.searchBarPopup} ref={searchBarPopupRef}>
                <div className={"ErrorFetchingData"}>
                    <span>Something went wrong</span>
                </div>
            </div>
        ) : (
            <div className={classes.searchBarPopup} ref={searchBarPopupRef}>
                {popupItems.length === 0 ? (
                    <div className={"NoResultsFound"}>
                        <span>No results found</span>
                    </div>
                ) : (
                    popupItems.map((item) => {
                        const shipment = item as IShipment;
                        if (shipment.shipmentDetails) {
                            return renderShipmentItem(shipment);
                        }
                        const invoice = item as IInvoice;
                        if (invoice.invoiceNumber) {
                            return renderInvoiceItem(invoice);
                        }
                        return null;
                    })
                )}
                {(isFetchingShipments || isFetchingSearchedInvoices) && !didFinishedPossibleSearches && searchLoader}
            </div>
        );
    };

    return !isMobile ? (
        <PillContainer height="56px" className={classes.overviewPageHeaderContainer}>
            <h2 className={classes.headLeft}>
                {`Hey${userState.name ? ` ${userState.name.split(" ")[0]}` : ""}, nice to see you |`}
                <span className={classes.customerNameSpan}>&nbsp;{userState?.customerName}</span>
            </h2>
            <div className={classes.headerRight}>
                {shouldShowLoader ? (
                    renderLoader
                ) : (
                    <Fragment>
                        {invoicesData && invoicesData.summary.invoicesCount > 0 && (
                            <Fragment>
                                <p className={classes.unpaidInvoicesContainer}>
                                    You have{" "}
                                    <span className={classes.unpaidInvoicesCount}>
                                        {invoicesData.summary.invoicesCount}
                                    </span>{" "}
                                    unpaid invoices
                                </p>
                                <div onClick={handleGetReviewAndPay} className={classes.reviewAndPayContainer}>
                                    Review & Pay
                                </div>
                            </Fragment>
                        )}
                        <div ref={searchContainerEl}>
                            <SearchBar
                                onChange={handleSearchChanged}
                                value={searchValue}
                                width="230px"
                                height="32px"
                                onClick={() => tryTrackEvent("[Overview Page]: Search bar in the top bar clicked")}
                            />
                            <Popper
                                open={Boolean(searchAnchorEl)}
                                anchorEl={searchContainerEl.current}
                                className={classes.searchBarPopper}
                                placement="bottom-end"
                            >
                                {renderPopupItems()}
                            </Popper>
                        </div>
                    </Fragment>
                )}
            </div>
        </PillContainer>
    ) : (
        <div className={classes.mobileSearch} ref={searchContainerEl}>
            <SearchBar
                onChange={handleSearchChanged}
                value={searchValue}
                backgroundColor="white"
                width="100%"
                height="32px"
                onClick={() => tryTrackEvent("[Overview Page]: Search bar in the top bar clicked")}
            />
            <Popper
                open={Boolean(searchAnchorEl)}
                anchorEl={searchContainerEl.current}
                className={classes.searchBarPopper}
                sx={{ width: searchContainerEl?.current?.clientWidth }}
                placement="bottom-start"
            >
                {renderPopupItems()}
            </Popper>
        </div>
    );
};

export default Header;
