import { IShipmentDetails, IShipmentStep } from "../../models";
import { CardName, ShipmentMoveType } from "./types";
import { BoatIconV2, TruckIconV2, AirplaneIconV2, WarehouseIconV2 } from "../../assets/icons";

interface IMakeFlatSteps {
    steps: IShipmentStep[];
    shipmentMoveType: ShipmentMoveType;
    shipmentDetails: IShipmentDetails;
}

export interface IFlatStep {
    translationObject:
        | "pickup"
        | "mainCarriage"
        | "transshipment"
        | "onCarriage"
        | "pickupForDelivery"
        | "availableForDelivery";
    arrivalOrDeparture: "arrival" | "departure";
    name: keyof typeof flatSteps;
    cardName: CardName;
    icon?: any;
    destination?: string;
    ATA?: string;
    ETA?: string | string[];
    ATD?: string;
    ETD?: string | string[];
}

export const shipmentIcons = {
    Air: AirplaneIconV2,
    Inland: TruckIconV2,
    Ocean: BoatIconV2,
    Warehouse: WarehouseIconV2,
};

export const flatSteps = {
    pickup: "pickup",
    arrivalToOriginPort: "arrivalToOriginPort",
    departureOriginPortInOrigin: "departureOriginPortInOrigin",
    departureOriginPortInTransit: "departureOriginPortInTransit",
    arrivalToDestinationPort: "arrivalToDestinationPort",
    transshipment1ArrivalToPort: "transshipment1ArrivalToPort",
    transshipment1DepartureToPort: "transshipment1DepartureToPort",
    transshipment2ArrivalToPort: "transshipment2ArrivalToPort",
    transshipment2DepartureToPort: "transshipment2DepartureToPort",
    transshipment3ArrivalToPort: "transshipment3ArrivalToPort",
    transshipment3DepartureToPort: "transshipment3DepartureToPort",
    onCarriageDepartureToDestinationTerminal: "onCarriageDepartureToDestinationTerminal",
    onCarriageArrivalToDestinationTerminal: "onCarriageArrivalToDestinationTerminal",
    availableForDelivery: "availableForDelivery",
    outForDelivery: "outForDelivery",
    delivery: "delivery",
};

const makeFlatSteps = ({ steps, shipmentMoveType, shipmentDetails }: IMakeFlatSteps) => {
    const existingSteps: IFlatStep[] = [];
    let availableForDeliveryLocation: string | null | undefined;
    const finalMainCarriageATA = shipmentDetails.mainCarriageFinalATA as string;
    const finalMainCarriageETA = shipmentDetails.mainCarriageFinalETA as string[];
    const fromCountryName = shipmentDetails.fromLocation?.countryName;
    const toCountryName = shipmentDetails.destLocation?.countryName;
    const fromCity = shipmentDetails.fromLocation?.city;

    const isInland = shipmentDetails.transportMode === "Inland";

    let mainCarriageFinalCityDestination: any = "";
    let mainCarriageFinalCountryDestination: any = "";

    // Arrival steps should take the "destination" (ATA / ETA)
    // Departure steps should take the "location" (ATD / ETD)

    steps.map((step, index) => {
        const nextStep = steps[index + 1];
        const isNextStepIsTransshipment =
            nextStep &&
            nextStep.stepName.includes("transshipment") &&
            (nextStep.ATA || nextStep.ATD || nextStep.ETA?.length || nextStep.ETD?.length);

        // Pickup step (pickup.ATD / pickup.ETD) shouldn't appear in PortToPort and PortToDoor
        if (step.stepName === "pickup" && shipmentMoveType !== "PortToPort" && shipmentMoveType !== "PortToDoor") {
            existingSteps.push({
                translationObject: "pickup",
                arrivalOrDeparture: "departure",
                name: "pickup",
                icon: shipmentIcons.Warehouse,
                cardName: "origin",
                ATD: step.ATD,
                ETD: step.ETD,
                // If step location wasn't entered we can take the value on the "from city" from shipment details
                destination: `${step.location || fromCity}${fromCountryName ? ", " + fromCountryName : ""}`,
            });
        }

        if (step.stepName === "pickup") {
            existingSteps.push({
                translationObject: "pickup",
                arrivalOrDeparture: "arrival",
                name: "arrivalToOriginPort",
                icon:
                    // Because first step icon should be warehouse
                    shipmentMoveType !== "PortToPort" && shipmentMoveType !== "PortToDoor"
                        ? shipmentIcons[shipmentDetails.transportMode]
                        : shipmentIcons.Warehouse,
                cardName: "origin",
                ATA: step.ATA,
                ETA: step.ETA,
                // Take the country from the next step *location* because we don't have it in the current step
                // If the step destination doesn't exist, take the location from the next step
                // In PortToPort and PortToDoor,the first step location in the summary will be different because they take the pickup location
                destination: `${step.destination || nextStep.location}${
                    nextStep?.locationCountry ? ", " + nextStep?.locationCountry : ""
                }`,
            });
        }

        if (step.stepName === "mainCarriage" && ((step.ETD?.length && !step.ATD) || (!step.ETD?.length && !step.ATD))) {
            existingSteps.push({
                translationObject: "mainCarriage",
                arrivalOrDeparture: "departure",
                cardName: isInland ? "inTransit" : "origin",
                ETD: step.ETD,
                destination: `${step.location}${step.locationCountry ? ", " + step.locationCountry : ""}`,
                name: "departureOriginPortInOrigin",
            });
        }

        // Logic as follow (pay attention that transshipment and onCarriage are not mandatory)
        // MainCarriageLocation: mainCarriageETD/ATD ->
        // Transshipment1Location: mainCarriageETA/ATA, transshipment1ETD/ATD (2 rows in one step!!!) ->
        // Transshipment2Location: transshipment1ETA/ATA, transshipment2ETD/ATD (2 rows in one step!!!) ->
        // Transshipment3Location: transshipment2ETA/ATA, transshipment3ETD/ATD (2 rows in one step!!!) ->
        // MainCarriageDestination: transshipment3ETA/ATA || mainCarriageFinalEAT/ATA ->
        // OnCarriageLocation (should be equal to MainCarriageDestination): onCarriageETD/ATD ->
        // OnCarriageDestination: onCarriageETA/ATA

        if (
            (step.stepName === "mainCarriage" && step.ATD) ||
            (step.stepName === "mainCarriage" && !step.ATD && step.ETD?.length)
        ) {
            existingSteps.push({
                translationObject: "mainCarriage",
                arrivalOrDeparture: "departure",
                cardName: "inTransit",
                ATD: step.ATD,
                ETD: step.ETD,
                destination: `${step.location}${step.locationCountry ? ", " + step.locationCountry : ""}`,
                name: "departureOriginPortInTransit",
            });
        }

        if (step.stepName === "mainCarriage") {
            // Need to save the mainCarriage destination because it has to be at the end of all transshipment (if exist)
            mainCarriageFinalCityDestination = step.destination;
            mainCarriageFinalCountryDestination = step.destinationCountry;

            existingSteps.push({
                translationObject: "mainCarriage",
                arrivalOrDeparture: "arrival",
                ATA: step.ATA,
                ETA: step.ETA,
                cardName: "inTransit",
                destination: isNextStepIsTransshipment
                    ? // If next step is transshipment, we will take the transshipment location
                      `${nextStep?.location}${step.locationCountry ? ", " + nextStep?.locationCountry : ""}`
                    : `${step.destination}${step.destinationCountry ? ", " + step.destinationCountry : ""}`,
                name: "arrivalToDestinationPort",
            });

            // Available for delivery doesn't have location/destination so we always need to take it from the step before it
            // It could be from the mainCarriage destination or from the onCarriage destination
            availableForDeliveryLocation = `${step.destination}${
                step.destinationCountry ? ", " + step.destinationCountry : ""
            }`;
        }

        if (step.stepName === "transshipment1" && (step.ATD || step.ETD?.length)) {
            existingSteps.push({
                translationObject: "transshipment",
                arrivalOrDeparture: "departure",
                cardName: "inTransit",
                ETD: step.ETD,
                ATD: step.ATD,
                // The location is the location of the transshipment
                destination: `${step.location}${step.locationCountry ? ", " + step.locationCountry : ""}`,
                name: "transshipment1DepartureToPort",
            });
        }

        if (step.stepName === "transshipment1" && (step.ATA || step.ETA?.length)) {
            existingSteps.push({
                translationObject: "transshipment",
                arrivalOrDeparture: "arrival",
                cardName: "inTransit",
                ATA: !isNextStepIsTransshipment ? finalMainCarriageATA : step.ATA,
                ETA: !isNextStepIsTransshipment ? finalMainCarriageETA : step.ETA,
                destination: isNextStepIsTransshipment
                    ? `${nextStep?.location}${nextStep.locationCountry ? ", " + nextStep?.locationCountry : ""}`
                    : mainCarriageFinalCityDestination + ", " + mainCarriageFinalCountryDestination,
                name: "transshipment1ArrivalToPort",
            });
            availableForDeliveryLocation =
                mainCarriageFinalCityDestination + ", " + mainCarriageFinalCountryDestination;
        }

        if (step.stepName === "transshipment2" && (step.ATD || step.ETD?.length)) {
            existingSteps.push({
                translationObject: "transshipment",
                arrivalOrDeparture: "departure",
                cardName: "inTransit",
                ETD: step.ETD,
                ATD: step.ATD,
                destination: `${step.location}${step.locationCountry ? ", " + step.locationCountry : ""}`,
                name: "transshipment2DepartureToPort",
            });
        }

        if (step.stepName === "transshipment2" && (step.ATA || step.ETA?.length)) {
            existingSteps.push({
                translationObject: "transshipment",
                arrivalOrDeparture: "arrival",
                ATA: !isNextStepIsTransshipment ? finalMainCarriageATA : step.ATA,
                ETA: !isNextStepIsTransshipment ? finalMainCarriageETA : step.ETA,
                cardName: "inTransit",
                destination: isNextStepIsTransshipment
                    ? `${nextStep?.location}${nextStep.locationCountry ? ", " + nextStep?.locationCountry : ""}`
                    : `${mainCarriageFinalCityDestination}${
                          mainCarriageFinalCountryDestination ? ", " + mainCarriageFinalCountryDestination : ""
                      }`,
                name: "transshipment2ArrivalToPort",
            });
            availableForDeliveryLocation =
                mainCarriageFinalCityDestination + ", " + mainCarriageFinalCountryDestination;
        }

        // This is the last possible transshipment
        if (step.stepName === "transshipment3" && (step.ATD || step.ETD?.length)) {
            existingSteps.push({
                translationObject: "transshipment",
                arrivalOrDeparture: "departure",
                cardName: "inTransit",
                ETD: step.ETD,
                ATD: step.ATD,
                destination: `${step.location}${step.locationCountry ? ", " + step.locationCountry : ""}`,
                name: "transshipment3DepartureToPort",
            });
        }

        if (step.stepName === "transshipment3" && (step.ATA || step.ETA?.length)) {
            existingSteps.push({
                translationObject: "transshipment",
                arrivalOrDeparture: "arrival",
                ATA: !isNextStepIsTransshipment ? finalMainCarriageATA : step.ATA,
                ETA: !isNextStepIsTransshipment ? finalMainCarriageETA : step.ETA,
                cardName: "inTransit",
                destination: `${mainCarriageFinalCityDestination} + ", " + ${mainCarriageFinalCountryDestination}`,
                name: "transshipment3ArrivalToPort",
            });
            availableForDeliveryLocation =
                mainCarriageFinalCityDestination + ", " + mainCarriageFinalCountryDestination;
        }

        if (step.stepName === "onCarriage" && (step.ATD || step.ETD?.length)) {
            existingSteps.push({
                translationObject: "onCarriage",
                arrivalOrDeparture: "departure",
                cardName: "inTransit",
                icon: shipmentIcons.Inland,
                ETD: step.ETD,
                ATD: step.ATD,
                // This location should be equal to mainCarriageDestination
                destination: `${step.location}${step.locationCountry ? ", " + step.locationCountry : ""}`,
                name: "onCarriageDepartureToDestinationTerminal",
            });
        }
        if (step.stepName === "onCarriage" && (step.ATA || step.ETA?.length)) {
            existingSteps.push({
                translationObject: "onCarriage",
                arrivalOrDeparture: "arrival",
                icon: shipmentIcons.Inland,
                ATA: step.ATA,
                ETA: step.ETA,
                cardName: "inTransit",
                destination: `${step.destination}${step.destinationCountry ? ", " + step.destinationCountry : ""}`,
                name: "onCarriageArrivalToDestinationTerminal",
            });
            availableForDeliveryLocation = `${step.destination}${
                step.destinationCountry ? ", " + step.destinationCountry : ""
            }`;
        }
        if (
            step.stepName === "availableForDelivery" &&
            shipmentMoveType !== "PortToPort" &&
            shipmentMoveType !== "DoorToPort"
        ) {
            existingSteps.push({
                translationObject: "availableForDelivery",
                arrivalOrDeparture: "arrival",
                cardName: "destination",
                // This step don't have location/destination. we need to show here the main carriage ATA destination
                destination: `${availableForDeliveryLocation}`,
                ATA: step.ATA,
                name: "availableForDelivery",
                icon: shipmentIcons.Warehouse,
            });
        }
        if (
            step.stepName === "pickupForDelivery" &&
            shipmentMoveType !== "PortToPort" &&
            shipmentMoveType !== "DoorToPort"
        ) {
            existingSteps.push({
                translationObject: "pickupForDelivery",
                arrivalOrDeparture: "departure",
                name: "outForDelivery",
                icon: shipmentIcons.Inland,
                cardName: "destination",
                ETD: step.ETD,
                ATD: step.ATD,
                destination: `${step.location}${toCountryName ? ", " + toCountryName : ""}`,
            });
        }
        if (
            step.stepName === "pickupForDelivery" &&
            shipmentMoveType !== "PortToPort" &&
            shipmentMoveType !== "DoorToPort"
        ) {
            existingSteps.push({
                translationObject: "pickupForDelivery",
                arrivalOrDeparture: "arrival",
                name: "delivery",
                icon: shipmentIcons.Warehouse,
                ATA: step.ATA,
                ETA: step.ETA,
                cardName: "destination",
                destination: `${step.destination}${toCountryName ? ", " + toCountryName : ""}`,
            });
        }
    });

    const desiredOrder = [
        flatSteps.pickup,
        flatSteps.arrivalToOriginPort,
        flatSteps.departureOriginPortInOrigin,
        flatSteps.departureOriginPortInTransit,
        flatSteps.arrivalToDestinationPort,
        flatSteps.transshipment1DepartureToPort,
        flatSteps.transshipment1ArrivalToPort,
        flatSteps.transshipment2DepartureToPort,
        flatSteps.transshipment2ArrivalToPort,
        flatSteps.transshipment3DepartureToPort,
        flatSteps.transshipment3ArrivalToPort,
        flatSteps.onCarriageDepartureToDestinationTerminal,
        flatSteps.onCarriageArrivalToDestinationTerminal,
        flatSteps.availableForDelivery,
        flatSteps.outForDelivery,
        flatSteps.delivery,
    ];

    existingSteps.sort((stepA: any, stepB: any) => {
        const indexA = desiredOrder.indexOf(stepA.name);
        const indexB = desiredOrder.indexOf(stepB.name);
        return indexA - indexB;
    });

    return existingSteps;
};

export default makeFlatSteps;
