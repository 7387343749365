import { useEffect, useMemo, useState } from "react";
import RfqHeader from "../../components/RFQ/Header/header";
import FirstPhaseForm from "./first-form/FirstPhaseForm";
import { RfqStep } from "../../models/rfq/rfq-types";
import SecondPhaseForm from "./second-form/SecondPhaseForm";
import ThirdPhaseForm from "./third-form/ThirdPhaseForm";
import formUseStyles from "./useStyles";
import { useAppDispatch, useAppSelector } from "../../store";
import FinalPhaseForm from "./final-form/FinalPhaseForm";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getStorageItem } from "../../helpers/services";
import { ICustomer } from "../../models";
import { userLoggedIn } from "../../store/reducers/usersSlice.store";
import { SESSION_STORAGE } from "../../assets/data/resources";
import { initMixpanel, setUserId, tryTrackEvent } from "../../helpers/services/MixPanelService";
import { ScreenWidthType, UiSchemeV2 } from "../../assets/data/ui";
import { useScreenWidthType } from "../../helpers/custom-hooks";
import DecorativeLine from "../../components/ui-decorations/decorative-backgrounds/DecorativeLine";
import {
    EventMessages,
    insertGoogleAnalyticsScript,
    sendGoogleAnalyticsEvent,
} from "../../helpers/services/GoogleAnalyticsService.service";

const STRING_EMPTY = "";
const RfqPage = () => {
    useEffect(() => {
        const referrer = document.referrer;
        if (authStatus !== "authenticated") {
            initMixpanel();
            tryTrackEvent("[RFQ]: Page viewed");
            insertGoogleAnalyticsScript();
            sendGoogleAnalyticsEvent(EventMessages.RFQPageViewNotLoggedIn, referrer);
        }
    }, []);

    const classes = formUseStyles();
    const [formStep, setFormStep] = useState<RfqStep>(RfqStep.FirstStep);
    const { authStatus, user } = useAuthenticator();
    const dispatch = useAppDispatch();
    const userData = useAppSelector((state) => state.rootReducer.users);
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;

    const currentStep = useMemo(() => {
        if (formStep === RfqStep.FinalStep) return RfqStep.ThirdStep;
        return formStep;
    }, [formStep]);

    useEffect(() => {
        if (authStatus === "authenticated") {
            const customerData = getStorageItem<ICustomer>(SESSION_STORAGE.customerData);
            const newUserData: ICustomer = {
                email: user.attributes?.email || STRING_EMPTY,
                sub: user.attributes?.sub,
                token: user.getSignInUserSession()?.getAccessToken().getJwtToken(),
                code: customerData?.code ?? STRING_EMPTY,
                name: customerData?.name ?? STRING_EMPTY,
                isNewUser: customerData?.isNewUser ?? false,
                customerName: customerData?.customerName ?? STRING_EMPTY,
            };
            dispatch(userLoggedIn(newUserData));

            const isClientCodeExist = newUserData.code ? true : false;

            if (formStep === RfqStep.FinalStep && isClientCodeExist) {
                setFormStep(RfqStep.ThirdStep);
            }
            const referrer = document.referrer;
            sendGoogleAnalyticsEvent(EventMessages.RFQPageViewLoggedIn, referrer);
        }
    }, [authStatus, user, userData.code]);

    const renderActiveSteps = () => {
        scrollTo(0, 0);
        switch (formStep) {
            case RfqStep.FirstStep:
                return <FirstPhaseForm updateFormState={setFormStep} />;
            case RfqStep.SecondStep:
                return <SecondPhaseForm updateFormState={setFormStep} />;
            case RfqStep.ThirdStep:
                return <ThirdPhaseForm updateFormState={setFormStep} />;
            case RfqStep.FinalStep:
                return <FinalPhaseForm updateFormState={setFormStep} />;
        }
    };

    return (
        <div className={classes.RFQPageContainer}>
            <RfqHeader currentStep={currentStep} updateFormState={setFormStep} />

            <div className={classes.container}>
                <div className={classes.formWrapper}>{renderActiveSteps()}</div>
            </div>

            {!isMobile && (
                <DecorativeLine
                    width="100%"
                    colorLeftLine={UiSchemeV2.colors.primaryLight}
                    colorRightLine={UiSchemeV2.colors.secondaryGreen}
                    colorMiddleCircle={"#05B834"}
                    leftLinePercentage={60}
                    height="52px"
                    borderRadius="0"
                />
            )}
        </div>
    );
};

export default RfqPage;
