export const currentStepLabelText = {
    pickup: {
        DoorToDoor: {
            FCL: "Picked up on:",
            LCL: "Picked up on:",
            Air: "Picked up on:",
            Express: "Picked up on:",
            LTL: "Picked up on:",
            FTL: "Picked up on:",
        },
        DoorToPort: {
            FCL: "Picked up on:",
            LCL: "Picked up on:",
            Air: "Picked up on:",
            Express: "",
            LTL: "",
            FTL: "",
        },
        PortToPort: {
            FCL: "Arrived To Port on:",
            LCL: "Arrived To CFS on:",
            Air: "Arrived To Airport on:",
            Express: "",
            LTL: "",
            FTL: "",
        },
        PortToDoor: {
            FCL: "Arrived To Port on:",
            LCL: "Arrived To CFS on:",
            Air: "Arrived To Airport on:",
            Express: "",
            LTL: "",
            FTL: "",
        },
    },
    departed: {
        DoorToDoor: {
            FCL: "Vessel Departed On:",
            LCL: "Vessel Departed On:",
            Air: "Flight Departed On:",
            Express: "In Transit",
            LTL: "In Transit",
            FTL: "In Transit",
        },
        DoorToPort: {
            FCL: "Vessel Departed On:",
            LCL: "Vessel Departed On:",
            Air: "Flight Departed On:",
            Express: "",
            LTL: "",
            FTL: "",
        },
        PortToPort: {
            FCL: "Vessel Departed On:",
            LCL: "Vessel Departed On:",
            Air: "Flight Departed On:",
            Express: "",
            LTL: "",
            FTL: "",
        },
        PortToDoor: {
            FCL: "Vessel Departed On:",
            LCL: "Vessel Departed On:",
            Air: "Flight Departed On:",
            Express: "",
            LTL: "In Transit",
            FTL: "In Transit",
        },
    },
    arrived: {
        DoorToDoor: {
            FCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            LCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            Air: {
                onCarriage: "Flight Arrived to destination on:",
                onCarriageNull: "Flight Arrived to destination on:",
            },
            Express: {
                onCarriage: "Arrived to Destination Country on:",
                onCarriageNull: "Arrived to Destination Country on:",
            },
            LTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
            FTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
        },
        DoorToPort: {
            FCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            LCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            Air: {
                onCarriage: "Flight Arrived to destination on:",
                onCarriageNull: "Flight Arrived to destination on:",
            },
            Express: {
                onCarriage: "Arrived to Destination Country on:",
                onCarriageNull: "Arrived to Destination Country on:",
            },
            LTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
            FTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
        },
        PortToPort: {
            FCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            LCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            Air: {
                onCarriage: "Flight Arrived to destination on:",
                onCarriageNull: "Flight Arrived to destination on:",
            },
            Express: {
                onCarriage: "Arrived to Destination Country on:",
                onCarriageNull: "Arrived to Destination Country on:",
            },
            LTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
            FTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
        },
        PortToDoor: {
            FCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            LCL: {
                onCarriage: "Arrived to Destination Terminal on:",
                onCarriageNull: "Vessel Arrived to Port on:",
            },
            Air: {
                onCarriage: "Flight Arrived to destination on:",
                onCarriageNull: "Flight Arrived to destination on:",
            },
            Express: {
                onCarriage: "Arrived to Destination Country on:",
                onCarriageNull: "Arrived to Destination Country on:",
            },
            LTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
            FTL: {
                onCarriage: "",
                onCarriageNull: "",
            },
        },
    },
    delivered: {
        DoorToDoor: {
            FCL: "Shipment Delivered On:",
            LCL: "Shipment Delivered On:",
            Air: "Shipment Delivered On:",
            Express: "Shipment Delivered On:",
            LTL: "Shipment Delivered On:",
            FTL: "Shipment Delivered On:",
        },
        DoorToPort: { FCL: "", LCL: "", Air: "", Express: "", LTL: "", FTL: "" },
        PortToPort: { FCL: "", LCL: "", Air: "", Express: "", LTL: "", FTL: "" },
        PortToDoor: {
            FCL: "Shipment Delivered On:",
            LCL: "Shipment Delivered On:",
            Air: "Shipment Delivered On:",
            Express: "",
            LTL: "Shipment Delivered On:",
            FTL: "Shipment Delivered On:",
        },
    },
};

export const nextStepLabelText = {
    pickup: {
        DoorToDoor: {
            FCL: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            LCL: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            Air: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            Express: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            LTL: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            FTL: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
        },
        DoorToPort: {
            FCL: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            LCL: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            Air: {
                TimeValue: "Pickup Expected on:",
                TimeValueNull: "Scheduling Pickup",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
        PortToPort: {
            FCL: {
                TimeValue: "Expected Arrival To Port:",
                TimeValueNull: "Scheduling Arrival To Port",
            },
            LCL: {
                TimeValue: "Expected Arrival To CFS:",
                TimeValueNull: "Scheduling Arrival to CFS",
            },
            Air: {
                TimeValue: "Expected Arrival To Airport on:",
                TimeValueNull: "Scheduling Arrival to Airport",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
        PortToDoor: {
            FCL: {
                TimeValue: "Expected Arrival To Port:",
                TimeValueNull: "Scheduling Arrival To Port",
            },
            LCL: {
                TimeValue: "Expected Arrival To CFS:",
                TimeValueNull: "Scheduling Arrival to CFS",
            },
            Air: {
                TimeValue: "Expected Arrival To Airport:",
                TimeValueNull: "Scheduling Arrival to Airport",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
    },
    departed: {
        DoorToDoor: {
            FCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            LCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Air: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Express: {
                TimeValue: "Expected to Depart Origin Facility",
                TimeValueNull: "Expected to Depart Origin Facility",
            },
            LTL: {
                TimeValue: "Expected to Depart Origin Facility",
                TimeValueNull: "Expected to Depart Origin Facility",
            },
            FTL: {
                TimeValue: "Expected to Depart Origin Facility",
                TimeValueNull: "Expected to Depart Origin Facility",
            },
        },
        DoorToPort: {
            FCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            LCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Air: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
        PortToPort: {
            FCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            LCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Air: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
        PortToDoor: {
            FCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            LCL: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Air: {
                TimeValue: "Expected to Depart on:",
                TimeValueNull: "Booking Your Shipment",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
    },
    arrived: {
        DoorToDoor: {
            FCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            LCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            Air: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Airport",
            },
            Express: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Destination Country",
            },
            LTL: {
                TimeValue: "Delivery Scheduled on:",
                TimeValueNull: "Scheduling Delivery Appointment",
            },
            FTL: {
                TimeValue: "Delivery Scheduled on:",
                TimeValueNull: "Scheduling Delivery Appointment",
            },
        },
        DoorToPort: {
            FCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            LCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            Air: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Airport",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
        PortToPort: {
            FCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            LCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            Air: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Airport",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
            FTL: {
                TimeValue: "",
                TimeValueNull: "",
            },
        },
        PortToDoor: {
            FCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            LCL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Vessel Arrival to Port",
            },
            Air: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Airport",
            },
            Express: {
                TimeValue: "",
                TimeValueNull: "",
            },
            LTL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Airport",
            },
            FTL: {
                TimeValue: "Arrival Expected on:",
                TimeValueNull: "Arrival to Airport",
            },
        },
    },
    delivered: {
        DoorToDoor: {
            FCL: {
                ATANullETANull: "Pending Container Availability",
                ATAETANull: "Scheduling Delivery Appointment",
                ATAETA: "Delivery Scheduled on:",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            LCL: {
                ATANullETANull: "Pending Cargo Availability",
                ATAETANull: "Scheduling Delivery Appointment",
                ATAETA: "Delivery Scheduled on:",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            Air: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            Express: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            LTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            FTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
        },
        DoorToPort: {
            FCL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            LCL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            Air: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            Express: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            LTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            FTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
        },
        PortToPort: {
            FCL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            LCL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            Air: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            Express: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            LTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            FTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
        },
        PortToDoor: {
            FCL: {
                ATANullETANull: "Pending Container Availability",
                ATAETANull: "Scheduling Delivery Appointment",
                ATAETA: "Delivery Scheduled on:",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            LCL: {
                ATANullETANull: "Pending Cargo Availability",
                ATAETANull: "Scheduling Delivery Appointment",
                ATAETA: "Delivery Scheduled on:",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            Air: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "Delivery Scheduled on:",
                ETANull: "Scheduling Delivery Appointment",
            },
            Express: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            LTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
            FTL: {
                ATANullETANull: "",
                ATAETANull: "",
                ATAETA: "",
                ETA: "",
                ETANull: "",
            },
        },
    },
};
