import { MenuListProps, MenuProps } from "@mui/material";
import { COLORS } from "../../assets/data/ui";

const menuListPropsStyles: MenuListProps = {
    sx: {
        overflowY: "scroll",
        maxHeight: "300px",
    },
};
export const menuPropsStyles = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    PaperProps: {
        sx: {
            overflow: "hidden", // removes double scrollbar
            padding: "0px",
            maxHeight: "300px",
            maxWidth: "224px !important",
            paddingLeft: "0px",
            borderRight: `1px solid  ${COLORS.system.destination}`,
            borderBottom: `1px solid ${COLORS.system.destination}`,
            borderLeft: `1px solid ${COLORS.system.destination}`,
            borderRadius: "0px 0px 4px 4px !important",
            boxShadow: "none !important",
        },
    },
    MenuListProps: menuListPropsStyles,
} as MenuProps;
