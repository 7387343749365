import { makeStyles } from "@mui/styles";
import { COLORS, TYPOGRAPHY, UiSchemeV2 } from "../../../assets/data/ui";
import { transform } from "typescript";

const useStyles = makeStyles({
    stepperHorizontal: {
        width: "100%",
        transform: "translate(calc(10% - 8px), 0)",
    },

    stepHorizontal: {
        position: "relative",
        alignItems: "flex-start",
        padding: "0",

        "& .MuiStepConnector-root": {
            "& .MuiStepConnector-line": {
                border: "0px",
                height: "16px",
            },
        },
    },

    /** Upper Label */
    stepUpperLabel: {
        position: "absolute",
        top: "-70px",
        left: "-10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    upperLabelMessageTop: {
        fontSize: `${TYPOGRAPHY.title}`,
        color: UiSchemeV2.colors.greys.grey300,
        fontWeight: "500",
    },
    upperLabelMessageBottomContainer: {
        fontSize: `${TYPOGRAPHY.title}`,
        display: "flex",
        gap: "5px",
        color: UiSchemeV2.colors.primaryDark
    },
    upperLabelStepNameAndDate: {
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",

        "& div": {
            textAlign: "left"
        }
    },
    /** StepLabel (bottom messagae) */
    stepLabelHorizontal: {
        "&.MuiStepLabel-alternativeLabel": {
            // label container
            "& .MuiStepLabel-labelContainer": {
                position: "absolute",
                top: "20px",
                // actual label
                "& .MuiStepLabel-label": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    whiteSpace: "nowrap"
                },
            },
        },
    },
    customIcon: {},
    stepLabel: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: UiSchemeV2.colors.greys.grey300,
        fontWeight: "500",

        "& hasTextAfter": {
            maxWidth: "100%",
        },
    },
    stepLabelDate: {
        color: UiSchemeV2.colors.primaryDark,
    },
    firstStep: {
        borderRadius: "55px 0 0 55px"
    },
    lastStep: {
        borderRadius: "0 55px 55px 0",
    },
    firstLastStepLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: COLORS.medium,
        opacity: "0.7",
        width: "120px",
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&.date": {
            color: COLORS.main,
            opacity: "1",
        },
    },
    firstLastStepLabelDate: {
        fontWeight: "600",
    },
    stepUpperLabelDestinationText: {
        right: "50%",
        position: "absolute",
        top: "-45px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    stepUpperLabelDestinationDate: {
        right: "50%",
        position: "absolute",
        top: "-25px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    alignRight: {
        "& $stepUpperLabel": {
            alignItems: "flex-end",
            left: "unset",
            right: "48%",

            "& $upperLabelStepNameAndDate div": {
                textAlign: "right",
            }
        },
        "& $alignRightHelper": {
            marginLeft: "auto",
        },
        "& :has(>$alignRightHelper)": {
            transform: "translate(-50%, 0)",
        },
       
        
    },
    alignRightHelper: {}
    
});

export default useStyles;
