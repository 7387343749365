import { MuiTheme } from "../../../helpers";
import { Step, StepLabel, Stepper } from "@mui/material";
import useStyles from "./useStyles";
import { useCallback, useMemo } from "react";
import { IShipmentStep, IShipmentStepper, IStepProps } from "../../../models";
import { STRINGS } from "../resources";
import { StepsService } from "../services/StepsService";
import { getFormattedDate } from "../../../helpers/services/DateFormatsService.service";
import { CHIP_COLORS } from "../../blocks/shipments-list/resources";
import { UiSchemeV2 } from "../../../assets/data/ui";
import { ShipmentLoad, ShipmentMoveType } from "../../accordionStepper/types";
import { getShipmentMoveType } from "../../accordionStepper/utils";
import { currentStepLabelText, nextStepLabelText } from "./constant";

const UnicargoStepper = ({
    stage,
    stepsBeforeChanges,
    steps,
    activeStep = 0,
    fromLocation,
    destLocation,
    transportMode,
    shipmentDetails,
    ...rest
}: IShipmentStepper) => {
    const classes = useStyles();
    const stepsService = useMemo(() => {
        return new StepsService(activeStep, steps, transportMode);
    }, [steps, activeStep]);
    const stageColor = stage && CHIP_COLORS[stage];

    const hasOnCarriageStep = () => {
        const onCarriageStep = steps.filter((step) => step.stepName === "onCarriage")[0];
        return onCarriageStep ? true : false;
    };

    const buildActiveStepLabel = (
        activeStep: IShipmentStep,
        moveType: ShipmentMoveType,
        shipmentLoad: ShipmentLoad,
        hasOnCarriage: boolean,
    ) => {
        if (activeStep.stepName === "pickup") {
            return currentStepLabelText.pickup[moveType][shipmentLoad];
        }

        if (activeStep.stepName === "pickupForDelivery") {
            return currentStepLabelText.delivered[moveType][shipmentLoad];
        }

        // Process steps based on whether hasOnCarriage is true or false.
        if (hasOnCarriage) {
            if (activeStep.stepName === "mainCarriage") {
                return currentStepLabelText.departed[moveType][shipmentLoad];
            } else if (activeStep.stepName === "onCarriage") {
                return currentStepLabelText.arrived[moveType][shipmentLoad].onCarriage;
            }
        } else {
            if (activeStep.stepName === "mainCarriageDeparture") {
                return currentStepLabelText.departed[moveType][shipmentLoad];
            } else if (activeStep.stepName === "mainCarriageArrival") {
                return currentStepLabelText.arrived[moveType][shipmentLoad].onCarriageNull;
            }
        }
    };

    const calculateDateForActiveStep = (activeStep: IShipmentStep, hasOnCarriage: boolean) => {
        let date;

        // Directly process steps that are unaffected by hasOnCarriage
        if (activeStep.stepName === "pickup" || activeStep.stepName === "pickupForDelivery") {
            date = activeStep.stepName === "pickup" ? activeStep.ATD : activeStep.ATA;
        } else {
            // Process steps based on whether hasOnCarriage is true or false.
            if (hasOnCarriage) {
                if (activeStep.stepName === "mainCarriage") {
                    date = activeStep.ATD;
                } else if (activeStep.stepName === "onCarriage") {
                    date = activeStep.ATA;
                }
            } else {
                if (activeStep.stepName === "mainCarriageDeparture") {
                    date = activeStep.ATD;
                } else if (activeStep.stepName === "mainCarriageArrival") {
                    // Using the finalATA from the shipment details instead of the ATA from the step
                    date = shipmentDetails.mainCarriageFinalATA;
                }
            }
        }

        const dateToDisplay = extractDate(date);

        return dateToDisplay && dateToDisplay !== STRINGS.NULL_MSG ? getFormattedDate(dateToDisplay) : STRINGS.NULL_MSG;
    };

    const extractDate = (date: string | string[] | undefined | null) => {
        let dateToDisplay;
        if (Array.isArray(date)) {
            dateToDisplay = date.length > 0 ? date.slice(-1)[0] : STRINGS.NULL_MSG;
        } else {
            dateToDisplay = date;
        }

        return dateToDisplay;
    };

    const buildNextStepLabel = (
        nextStep: IShipmentStep,
        moveType: ShipmentMoveType,
        shipmentLoad: ShipmentLoad,
        hasOnCarriage: boolean,
    ) => {
        let message = " ";
        let date;

        if (hasOnCarriage) {
            if (nextStep.stepName === "pickup") {
                if (moveType === "DoorToDoor" || moveType === "DoorToPort") {
                    date = extractDate(nextStep.ETD);
                } else {
                    date = extractDate(nextStep.ETA);
                }
                message =
                    date && date !== STRINGS.NULL_MSG
                        ? nextStepLabelText.pickup[moveType][shipmentLoad].TimeValue
                        : nextStepLabelText.pickup[moveType][shipmentLoad].TimeValueNull;
            } else if (nextStep.stepName === "mainCarriage") {
                if (shipmentLoad === "FTL" || shipmentLoad === "LTL") {
                    // Using the finalETA from the shipment details instead of the ETA from the step
                    date = extractDate(shipmentDetails.mainCarriageFinalETA);
                } else {
                    date = extractDate(nextStep.ETD);
                }
                message =
                    date && date !== STRINGS.NULL_MSG
                        ? nextStepLabelText.departed[moveType][shipmentLoad].TimeValue
                        : nextStepLabelText.departed[moveType][shipmentLoad].TimeValueNull;
            } else if (nextStep.stepName === "onCarriage") {
                date = extractDate(nextStep.ETA);
                message =
                    date && date !== STRINGS.NULL_MSG
                        ? nextStepLabelText.arrived[moveType][shipmentLoad].TimeValue
                        : nextStepLabelText.departed[moveType][shipmentLoad].TimeValueNull;
            } else if (nextStep.stepName === "availableForDelivery" || nextStep.stepName === "pickupForDelivery") {
                const dateETA = extractDate(nextStep.ETA);
                if (
                    shipmentLoad === "Express" ||
                    shipmentLoad === "Air" ||
                    shipmentLoad === "FCL" ||
                    shipmentLoad === "LCL"
                ) {
                    message = dateETA
                        ? nextStepLabelText.delivered[moveType][shipmentLoad].ETA
                        : nextStepLabelText.delivered[moveType][shipmentLoad].ETANull;
                    date = dateETA;
                }
            }
        } else {
            if (nextStep.stepName === "pickup") {
                if (moveType === "DoorToDoor" || moveType === "DoorToPort") {
                    date = extractDate(nextStep.ETD);
                } else {
                    date = extractDate(nextStep.ETA);
                }
                message =
                    date && date !== STRINGS.NULL_MSG
                        ? nextStepLabelText.pickup[moveType][shipmentLoad].TimeValue
                        : nextStepLabelText.pickup[moveType][shipmentLoad].TimeValueNull;
            } else if (nextStep.stepName === "mainCarriageDeparture") {
                if (shipmentLoad === "FTL" || shipmentLoad === "LTL") {
                    date = null;
                } else {
                    date = extractDate(nextStep.ETD);
                }
                message =
                    date && date !== STRINGS.NULL_MSG
                        ? nextStepLabelText.departed[moveType][shipmentLoad].TimeValue
                        : nextStepLabelText.departed[moveType][shipmentLoad].TimeValueNull;
            } else if (nextStep.stepName === "mainCarriageArrival") {
                // Using the finalETA from the shipment details instead of the ETA from the step
                date = extractDate(shipmentDetails.mainCarriageFinalETA);
                message =
                    date && date !== STRINGS.NULL_MSG
                        ? nextStepLabelText.arrived[moveType][shipmentLoad].TimeValue
                        : nextStepLabelText.arrived[moveType][shipmentLoad].TimeValueNull;
            } else if (nextStep.stepName === "pickupForDelivery") {
                if (shipmentLoad === "FTL" || shipmentLoad === "LTL") {
                    date = extractDate(nextStep.ETA);
                    message =
                        date && date !== STRINGS.NULL_MSG
                            ? nextStepLabelText.arrived[moveType][shipmentLoad].TimeValue
                            : nextStepLabelText.arrived[moveType][shipmentLoad].TimeValueNull;
                }
                if (!nextStep.ATA) {
                    const dateETA = extractDate(nextStep.ETA);
                    if (
                        shipmentLoad === "Express" ||
                        shipmentLoad === "Air" ||
                        shipmentLoad === "FCL" ||
                        shipmentLoad === "LCL"
                    ) {
                        message = dateETA
                            ? nextStepLabelText.delivered[moveType][shipmentLoad].ETA
                            : nextStepLabelText.delivered[moveType][shipmentLoad].ETANull;
                        date = dateETA;
                    }
                }
            }
        }

        return (
            <div className={classes.stepUpperLabel}>
                <div className={classes.upperLabelMessageTop}>Coming up</div>
                <div className={`${classes.upperLabelMessageBottomContainer}  ${"nextStep"}`}>
                    <div className={classes.upperLabelStepNameAndDate}>
                        <div>{message}</div>
                        <div>
                            {date && date !== STRINGS.NULL_MSG ? getFormattedDate(date) : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const loadStepUpperLabel = (index: number, stepData: IShipmentStep) => {
        const isNextStep = stepsService.isNextStep(index);
        if (!isNextStep) {
            return null;
        }

        const hasOnCarriage = hasOnCarriageStep();
        const shipmentMoveType = getShipmentMoveType(shipmentDetails.moveTypeEnglishName);
        const shipmentLoad = shipmentDetails.shipmentLoad as ShipmentLoad;

        return buildNextStepLabel(stepData, shipmentMoveType, shipmentLoad, hasOnCarriage);
    };

    const loadFirstLastStepLabel = (city?: string, country?: string, date?: string) => {
        // If its the final step, render the delivered date as well
        const isFinalStepAndDateAvailable = !!date;
        return (
            <>
                {isFinalStepAndDateAvailable && (
                    <>
                        <div className={classes.stepUpperLabelDestinationText}>Delivered On</div>
                        <div className={`${classes.stepUpperLabelDestinationDate} ${classes.stepLabelDate}`}>
                            {getFormattedDate(date)}
                        </div>
                    </>
                )}
                <div className={`${classes.firstLastStepLabel} ${date ? "date" : ""}`}>
                    <div>{city}</div>
                    <div>{country}</div>
                </div>
            </>
        );
    };

    const loadActiveStepLabel = (stepData: IShipmentStep, hasTextAfter: boolean) => {
        const shipmentMoveType = getShipmentMoveType(shipmentDetails.moveTypeEnglishName);
        const shipmentLoad = shipmentDetails.shipmentLoad as ShipmentLoad;
        const hasOnCarriage = hasOnCarriageStep();
        const message = buildActiveStepLabel(stepData, shipmentMoveType, shipmentLoad, hasOnCarriage);
        const date = calculateDateForActiveStep(stepData, hasOnCarriage);

        // Should not present date with "In Transit" message.
        return (
            <>
                <div className={` ${classes.stepLabel} ${hasTextAfter && "hasTextAfter"}`}>{message}</div>
                {message != "In Transit" && (
                    <div className={`${classes.alignRightHelper} ${classes.stepLabel} ${classes.stepLabelDate} ${hasTextAfter && "hasTextAfter"}`}>
                        {date}
                    </div>
                )}
            </>
        );
    };

    // Loads the label by the current step.
    const loadStepBottomLabel = useCallback(
        (index: number, stepData: IShipmentStep) => {
            if (index === -1) {
                const depCity = fromLocation?.city || STRINGS.NULL_MSG;
                const depCountry = fromLocation?.country || STRINGS.NULL_MSG;
                return loadFirstLastStepLabel(depCity, depCountry);
            } else if (index === activeStep && index < steps.length - 1) {
                return index === steps.length - 2
                    ? loadActiveStepLabel(stepData, true)
                    : loadActiveStepLabel(stepData, false);
            } else if (index === steps.length - 1) {
                const arrivalCity = destLocation?.city || STRINGS.NULL_MSG;
                const arrivalCountry = destLocation?.country || STRINGS.NULL_MSG;
                // Send the date to the loadFirstLastStepLabel function only during the pickupForDelivery step (when shipment is not D2P or P2P)
                if (stepData.stepName === "pickupForDelivery") {
                    return loadFirstLastStepLabel(arrivalCity, arrivalCountry, stepData.ATA);
                } else {
                    return loadFirstLastStepLabel(arrivalCity, arrivalCountry);
                }
            } else {
                return null;
            }
        },
        [steps, transportMode],
    );

    const CustomIcon = () => {
        return <div className={classes.customIcon}></div>;
    };

    const renderStepBackgroundImage = (index: number) => {
        const isActive = stepsService.isStepActive(index, true);
        const isCompleted = stepsService.isStepCompleted(index);
        const isLastStep = index === steps.length - 1;
        const isFirstStep = index === -1;

        if (isFirstStep) {
            return `linear-gradient(to right, ${UiSchemeV2.colors.greys.white} 50%, transparent 50%)`;
        } else if (isLastStep) {
            return isActive
                ? `linear-gradient(to right, ${stageColor?.primary} 50%, ${UiSchemeV2.colors.greys.white} 50%)`
                : `linear-gradient(to right, ${UiSchemeV2.colors.greys.background} 50%, ${UiSchemeV2.colors.greys.white} 50%)`;
        } else {
            return isCompleted
                ? `linear-gradient(to right, ${stageColor?.primary} 50%, transparent 50%)`
                : isActive
                ? `linear-gradient(to right, ${stageColor?.primary} 50%, ${UiSchemeV2.colors.greys.background} 50%)`
                : `linear-gradient(to right, ${UiSchemeV2.colors.greys.background} 50%, transparent 50%)`;
        }
    };

    const loadStepLabel = useCallback(
        (index: number, stepData: IShipmentStep = { stepName: "" }) => {
            const stepColor = stepsService.getStepColor(index, true, stageColor);

            return (
                <div
                    style={{
                        backgroundImage: renderStepBackgroundImage(index),
                        width: "16px",
                        height: "16px",
                    }}
                >
                    <StepLabel
                        className={classes.stepLabelHorizontal}
                        StepIconComponent={CustomIcon}
                        style={{
                            backgroundColor: stepColor,
                            color: stepColor,
                            width: "16px",
                            height: "16px",
                            borderRadius: "55px",
                        }}
                    >
                        {loadStepBottomLabel(index, stepData)}
                        {loadStepUpperLabel(index, stepData)}
                    </StepLabel>
                </div>
            );
        },
        [steps, transportMode],
    );

    const renderSteps = useMemo(() => {
        const stepsArray: JSX.Element[] = [];

        const isCompleted = activeStep > -1;

        stepsArray.push(
            <Step
                className={`${classes.stepHorizontal} ${classes.firstStep}`}
                style={{
                    backgroundColor: isCompleted ? stageColor?.primary : UiSchemeV2.colors.greys.background,
                }}
                key={"location-step"}
                completed={activeStep > -1}
                active={activeStep === -1}
            >
                {loadStepLabel(-1)}
            </Step>,
        );

        // loop over the actual steps and add them to the steps array.
        steps.forEach((stepData, i) => {
            const isLastStep = i === steps.length - 1;
            const lastStepClass = isLastStep ? classes.lastStep : "";
            const alignRightClass = activeStep === 2 ? classes.alignRight : "";
            const stepProps: IStepProps = {};
            if (stepsService.isStepCompleted(i)) {
                stepProps.completed = true;
            } else if (stepsService.isStepActive(i)) {
                stepProps.active = true;
            }
            const step = (
                <Step
                    className={`${classes.stepHorizontal} ${lastStepClass} ${alignRightClass}`}
                    style={{
                        backgroundColor: stepProps.completed
                            ? stageColor?.primary
                            : isLastStep
                            ? "transparent"
                            : UiSchemeV2.colors.greys.background,
                    }}
                    key={i}
                    {...stepProps}
                >
                    {loadStepLabel(i, stepData)}
                </Step>
            );
            stepsArray.push(step);
        });
        return stepsArray;
    }, [steps]);

    return (
        <MuiTheme>
            <Stepper
                className={classes.stepperHorizontal}
                activeStep={activeStep}
                alternativeLabel
                orientation="horizontal"
                {...rest}
            >
                {renderSteps}
            </Stepper>
        </MuiTheme>
    );
};

export default UnicargoStepper;
