import { Popover } from "@mui/material";
import { Fragment, useCallback, useEffect, useRef } from "react";
import { useMemo, useState } from "react";
import { ActivatedFilterIcon, FilterIcon } from "../../../assets/icons";
import { useSearchDebounce } from "../../../helpers/custom-hooks";
import {
    IShipmentQueryParams,
    checkInstanceOfShipmentStatus,
    checkInstanceOfShipmentType,
    ShipmentStatus,
    ShipmentType,
} from "../../../helpers/services/ShipmentService";
import { EShipmentStages, IChip, IShipment } from "../../../models";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useLazyGetShipmentsByCustomerCodeQuery } from "../../../store/api-slices/shipmentsSlice.api";
import { clearGlobalShipment } from "../../../store/reducers/shipmentsSlice.store";
import { ShipmentCardMobile } from "../../cards/shipment-cards/mobile-card/ShipmentCardMobile";
import { ShipmentListCard } from "../../cards/shipment-cards/shipment-list-card/ShipmentListCard";
import { SearchBar, ShipmentsDashboardEmptyState } from "../../";
import Block from "../block/Block";
import ShipmentsFilterPopup from "../filter-popup/ShipmentsFilterPopup";
import { CHIP_COLORS, FILTERS, FILTER_TO_QUERY_KEY, SHIPMENT_TYPES, STRINGS, TABS, TYPE_CHIP_ICONS } from "./resources";
import { calcActiveFilterChips, getQueryByTab, updateQueryObject } from "./services/shipment-list.service";
import useStyles from "./useStyles";
import { ScreenWidthType } from "../../../assets/data/ui";
import { useScreenWidthType } from "../../../helpers/custom-hooks/useScreenWidthType";
import ShipmentsListBlockCardLoader from "./ShipmentsListBlockCardLoader";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { useSearchParams } from "react-router-dom";
import ShipmentExportButton from "./ShipmentExportButton";

interface IShipmentListBlock {
    initialShipments?: IShipment[];
    onShipmentSelected: (shipment: IShipment) => void;
    selectedShipment?: IShipment;
}

const ShipmentsListBlock = ({ initialShipments, onShipmentSelected, selectedShipment }: IShipmentListBlock) => {
    const classes = useStyles();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const userState = useAppSelector((state) => state.rootReducer.users);
    const shipmentsState = useAppSelector((state) => state.rootReducer.shipments);
    const dispatch = useAppDispatch();
    const [triggerReq, reqResult] = useLazyGetShipmentsByCustomerCodeQuery();
    const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null);
    const [hasReachedBottom, setHasReachedBottom] = useState(false);
    const shipmentsFilterPopupRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const shipmentNumber = searchParams.get("shipmentNumber");

    // ! filter states
    const [currentTab, setCurrentTab] = useState(TABS.activeShipments);
    const [filteredShipments, setFilteredShipments] = useState<IShipment[]>();
    const [searchValue, setSearchValue] = useState("");
    const searchDebounceValue = useSearchDebounce(searchValue);
    const [activeChipFilters, setActiveChipFilters] = useState(FILTERS);
    const [queryParams, setQueryParams] = useState<IShipmentQueryParams>(getQueryByTab(TABS.activeShipments));

    const getNoActiveShipmentsClass = () => {
        return filteredShipments && filteredShipments.length ? "" : classes.noActiveShipment;
    };

    // ! To be removed:
    const [chipCounts, setChipCounts] = useState<{ [key: string]: number }>({
        Origin: 0,
        "In Transit": 0,
        Destination: 0,
        Completed: 0,
    });

    useEffect(() => {
        if (shipmentNumber) {
            handleTabSelected(TABS.allShipments);
            setSearchValue(shipmentNumber);
        }
    }, []);

    // #region Hooks
    useEffect(() => {
        triggerReq({
            customerCode: userState.code,
            params: { status: ["destination", "inTransit", "origin"] },
        });
        setFilteredShipments(initialShipments);
    }, []);

    useEffect(() => {
        const shipments = filteredShipments?.map((shipment) => {
            if (shipment.shipmentDetails.shipmentNumber === selectedShipment?.shipmentDetails.shipmentNumber) {
                // const changedShipment = { ...shipment };
                const changedShipment = structuredClone(shipment);
                changedShipment.shipmentDetails.shipmentName = selectedShipment?.shipmentDetails.shipmentName;
                return changedShipment;
            } else {
                return shipment;
            }
        });
        setFilteredShipments(shipments);
    }, [selectedShipment]);

    useEffect(() => {
        // When a shipment is selected in the OverviewPage's SearchBar component, it will jump to this hook.
        const { shipment, params } = shipmentsState;
        if (shipment && params) {
            // ? selectedShipment is set at ShipmentsPage component.
            setCurrentTab(TABS.allShipments);
            updateTriggerQuery(params);
            setSearchValue(params.search ?? "");
            triggerReq({ customerCode: userState.code, params });
        }
    }, [shipmentsState]);

    useEffect(() => {
        if (hasReachedBottom) {
            setHasReachedBottom(false);
        }
    }, [hasReachedBottom]);

    useEffect(() => {
        if (reqResult.isSuccess && reqResult.data) {
            const { shipments: responseShipments, counters } = reqResult.data;
            const { originCount, inTransitCount, destinationCount, closedCount } = counters;
            setFilteredShipments(responseShipments);
            setChipCounts({
                Origin: originCount,
                "In Transit": inTransitCount,
                Destination: destinationCount,
                Completed: closedCount,
            });
            if (
                (!isMobile || (isMobile && shipmentsState.shipment)) &&
                responseShipments &&
                responseShipments.length > 0
            ) {
                onShipmentSelected(shipmentsState.shipment ?? responseShipments[0]);
            }
        }
    }, [reqResult]);

    useEffect(() => {
        updateTriggerQuery(getQueryByTab(currentTab, queryParams));
    }, [currentTab]);

    // Update shipments by SearchBar value
    useEffect(() => {
        const updatedQueryParams: IShipmentQueryParams = structuredClone(queryParams); // ! deep copy the query params
        updatedQueryParams.search = searchDebounceValue;
        updateTriggerQuery(updatedQueryParams);
    }, [searchDebounceValue]);

    useEffect(() => {
        const iterableChipFilters = Object.keys(activeChipFilters);
        // Check if there are any active filters, else reset filter by tab
        if (iterableChipFilters.some((key) => activeChipFilters[key] === true)) {
            const newQueryParams: IShipmentQueryParams = {}; // create a new filter object
            iterableChipFilters.forEach((key) => {
                const value = activeChipFilters[key];
                if (value === true) {
                    const actualKey = FILTER_TO_QUERY_KEY[key];
                    if (actualKey) {
                        if (checkInstanceOfShipmentStatus(actualKey)) {
                            if (newQueryParams.status) {
                                updateQueryObject<ShipmentStatus>(newQueryParams.status, actualKey);
                            } else {
                                newQueryParams.status = [actualKey];
                            }
                        } else if (checkInstanceOfShipmentType(actualKey)) {
                            if (newQueryParams.type) {
                                updateQueryObject<ShipmentType>(newQueryParams.type, actualKey);
                            } else {
                                newQueryParams.type = [actualKey];
                            }
                        }
                        handleUpdateQueryByChips(newQueryParams);
                    }
                }
            });
        } else {
            setQueryParams(getQueryByTab(currentTab, queryParams));
        }
    }, [activeChipFilters]);

    //#endregion

    //#region Handlers & utils
    /**
     * The general rule of thumb is to always query when query params are updated to create a smooth states flow, but thats not allways that case.
     * Therefore, added an option to choose whether to also trigger a request or not.
     * @param {IShipmentQueryParams} newQueryParams - The new query params
     * @param {boolean} trigger - Decides whether to also trigger a request, or not.
     */
    const updateTriggerQuery = (newQueryParams: IShipmentQueryParams, trigger = true) => {
        setQueryParams(newQueryParams);
        if (trigger) {
            triggerReq({
                customerCode: userState.code,
                params: newQueryParams,
            });
        }
    };

    const handleFilterClicked = (event: React.MouseEvent<HTMLElement>) => {
        setFilterAnchorEl(event.currentTarget);
        tryTrackEvent("[Shipment page]: 'Filter' button clicked");
    };

    const handleConfirmFilterClicked = () => {
        triggerReq({
            customerCode: userState.code,
            params: queryParams,
        });
        setFilterAnchorEl(null);
    };

    // ? Util callback handlers for filter popup
    const handleFilterChipSelected = (key: string) => {
        const updatedFilters = { ...activeChipFilters };
        updatedFilters[key] = !updatedFilters[key];
        setActiveChipFilters(updatedFilters);
    };

    const handleTabSelected = (label: string) => {
        if (label !== currentTab) {
            setCurrentTab(label);
            if (label === TABS.allShipments) {
                tryTrackEvent("[Shipment page]: 'All Shipments' tab clicked");
            } else if (label === TABS.activeShipments) {
                tryTrackEvent("[Shipment page]: 'Active Shipments' tab clicked");
            }
            // reset filters + global shipment, if exists
            setActiveChipFilters(FILTERS);
            setQueryParams(getQueryByTab(label));
            setSearchValue("");
            if (shipmentsState.shipment) {
                dispatch(clearGlobalShipment());
            }
        }
    };

    const handleResetFilterClicked = () => {
        setActiveChipFilters(FILTERS);
        updateTriggerQuery(getQueryByTab(currentTab));
    };

    const handleUpdateQueryByChips = (newQueryParams: IShipmentQueryParams) => {
        if (newQueryParams.status && newQueryParams.status.length > 0) {
            setQueryParams(newQueryParams);
        } else {
            newQueryParams.status = getQueryByTab(currentTab).status;
            setQueryParams(newQueryParams);
        }
    };

    // ! Currently unused filters
    const handleFilterOriginSelected = () => {
        return;
    };
    const handleFilterDestinationSelected = () => {
        return;
    };
    //#endregion

    //#region Callback handlers
    const handleSearchChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleCardClicked = (shipment: IShipment) => {
        onShipmentSelected(shipment); // For displaying the shipment details in the drawer
    };

    useEffect(() => {
        const container = document.querySelector(`.${classes.ListContainer}`);
        if (container) {
            container.addEventListener("scroll", (event: Event) => handleCardsListScroll(event as any));
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", (event: Event) => handleCardsListScroll(event as any));
            }
        };
    }, []);

    // ? adds 20 cards when finishing scrolling untill the end of the container
    const handleCardsListScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const threshold = 50;
        const isBottom =
            event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
            event.currentTarget.clientHeight + threshold;
        if (
            isBottom &&
            reqResult.data &&
            filteredShipments &&
            reqResult.data.counters.currentCount > filteredShipments?.length &&
            !reqResult.isFetching
        ) {
            const updatedParams = structuredClone(queryParams);
            updatedParams.limit = updatedParams.limit ? (updatedParams.limit += 20) : 40;
            updateTriggerQuery(updatedParams);
            setHasReachedBottom(true);
        }
    };
    //#endregion

    const renderShipmentsListBlockLoader = (
        <Fragment>
            {Array.from(Array(10).keys()).map((_, index) => (
                <ShipmentsListBlockCardLoader key={index} />
            ))}
        </Fragment>
    );

    //#region Memoized values
    const renderStatusChips = useMemo(() => {
        // ? filter - if current tab is not "All Shipments" - filter out the "Completed" chip
        return Object.values(EShipmentStages)
            .filter((value) => (currentTab === TABS.allShipments ? true : value !== EShipmentStages.Completed))
            .map<IChip>((label) => {
                return {
                    label,
                    count: chipCounts[label],
                    color: CHIP_COLORS[label].primary,
                    onSelect: handleFilterChipSelected,
                    selected: activeChipFilters[label],
                };
            });
    }, [chipCounts, activeChipFilters, currentTab, queryParams]);

    const renderTypeChips = useMemo(() => {
        return Object.values(SHIPMENT_TYPES).map<IChip>((type) => {
            return {
                label: type,
                icon: TYPE_CHIP_ICONS[type],
                onSelect: handleFilterChipSelected,
                selected: activeChipFilters[type],
            };
        });
    }, [chipCounts, activeChipFilters, currentTab, queryParams]);

    const renderTabs = useMemo(() => {
        return Object.values(TABS).map((label) => (
            <div
                className={`${classes.TabContainer} ${currentTab === label && STRINGS.ACTIVE}`}
                key={label}
                onClick={() => handleTabSelected(label)}
            >
                <button className={`${classes.Tab}`}>{label}</button>
            </div>
        ));
    }, [currentTab]);

    const renderFilterPopup = useMemo(() => {
        return (
            <Popover
                ref={shipmentsFilterPopupRef}
                open={Boolean(filterAnchorEl)}
                anchorEl={filterAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setFilterAnchorEl(null)}
            >
                <ShipmentsFilterPopup
                    // TODO: inject select options
                    statusChips={renderStatusChips}
                    typeChips={renderTypeChips}
                    originSelectBox={{
                        options: [],
                        onSelect: handleFilterOriginSelected,
                    }}
                    destinationSelectBox={{
                        options: [],
                        onSelect: handleFilterDestinationSelected,
                    }}
                    onConfirmCallback={handleConfirmFilterClicked}
                    onResetCallback={handleResetFilterClicked}
                />
            </Popover>
        );
    }, [filterAnchorEl, renderStatusChips, renderTypeChips]); /* // TODO: add destination and origin selects values */

    const renderShipmentCards = useMemo(() => {
        if (!initialShipments && filteredShipments && filteredShipments.length === 0) {
            return null;
        }

        if (filteredShipments && filteredShipments.length) {
            if (isMobile) {
                return filteredShipments.map((shipment, index) => (
                    <ShipmentCardMobile
                        key={index}
                        shipmentData={shipment}
                        handleCardClicked={handleCardClicked}
                        query={searchValue}
                    />
                ));
            } else {
                return filteredShipments.map((shipment) => {
                    const { shipmentNumber } = shipment.shipmentDetails;
                    return (
                        <ShipmentListCard
                            key={shipmentNumber}
                            shipmentData={shipment}
                            query={searchValue}
                            selected={shipmentNumber === selectedShipment?.shipmentDetails.shipmentNumber}
                            onClick={handleCardClicked}
                        />
                    );
                });
            }
        } else {
            return <ShipmentsDashboardEmptyState />;
        }
    }, [
        isMobile,
        handleCardClicked,
        selectedShipment,
        initialShipments,
        filteredShipments,
        currentTab,
        searchDebounceValue,
        reqResult,
    ]);

    const renderActiveFiltersSum = useCallback(() => {
        return calcActiveFilterChips(activeChipFilters);
    }, [activeChipFilters]);
    //#endregion
    return (
        <Block className={`${classes.root} ${getNoActiveShipmentsClass()}`}>
            <div className={classes.ShipmentsListHead}>
                <div className={classes.TabsContainer}>{renderTabs}</div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <ShipmentExportButton
                        customerCode={userState.code}
                        queryParams={queryParams}
                        shipments={filteredShipments}
                    />
                    <button onClick={handleFilterClicked} className={`${classes.FilterButton}`}>
                        {renderActiveFiltersSum() <= 0 ? (
                            <div className={classes.filterIconAndTitle}>
                                <FilterIcon />
                                {STRINGS.FILTER}
                            </div>
                        ) : (
                            <div className={classes.filterIconAndTitle}>
                                <ActivatedFilterIcon />
                                {STRINGS.FILTER}
                                <span className={classes.activeFiltersCountText}>{renderActiveFiltersSum()}</span>
                            </div>
                        )}
                    </button>

                    {renderFilterPopup}
                </div>
            </div>
            <div className={classes.SearchContainer}>
                <SearchBar
                    onChange={handleSearchChanged}
                    backgroundColor="gray"
                    value={searchValue}
                    onClick={() => tryTrackEvent("[Shipment Page]: Search bar clicked")}
                />
            </div>
            <div className={classes.ListContainer} onScroll={handleCardsListScroll}>
                {
                    // If request is still fetching / searchDebouceValue is not updated to the latest value of searchValue
                    // TODO: Sync this loader with the Page loading state in the Shipment page.
                    searchValue !== searchDebounceValue || reqResult.isLoading
                        ? renderShipmentsListBlockLoader
                        : renderShipmentCards
                }
            </div>
        </Block>
    );
};

export default ShipmentsListBlock;
